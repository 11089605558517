import React, { FC } from 'react';

import SEO from 'src/components/organisms/common/SEO';
import Tag from 'src/components/organisms/tag/Tag';
import { ITag } from 'src/interfaces/template';
import usePosts from 'src/hooks/usePosts';
import Layout from 'src/components/templates/layout/Layout';

interface PropsType {
  data: GatsbyTypes.Query;
  tag: ITag;
}

const TagTemplate: FC<PropsType> = ({ data, tag }) => {
  const posts = usePosts(data.allMarkdownRemark);
  const title = `Tag: ${tag.name} (${posts.length})`;

  return (
    <Layout>
      <SEO title={title} />
      <Tag title={title} posts={posts} />
    </Layout>
  );
};

export default TagTemplate;
