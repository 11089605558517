import 'katex/dist/katex.min.css';
import React, { FC, memo } from 'react';
import { graphql } from 'gatsby';

import { ITag, ITemplateProps } from 'src/interfaces/template';
import TagTemplate from 'src/components/templates/tag/TagTemplate';

interface PropsType
  extends ITemplateProps<{
    tag: ITag;
  }> {}

const TagPageTemplate: FC<PropsType> = memo(({ pageContext, data }) => {
  return <TagTemplate data={data as GatsbyTypes.Query} tag={pageContext.tag} />;
});

export default TagPageTemplate;

export const pageQuery = graphql`
  query TagPage($name: String) {
    allMarkdownRemark(
      limit: 1000
      sort: { order: DESC, fields: frontmatter___date }
      filter: { frontmatter: { published: { eq: true }, tags: { in: [$name] } } }
    ) {
      edges {
        node {
          excerpt(truncate: true, pruneLength: 300)
          frontmatter {
            title
            date
            slug
            tags
            series
            comments
            published
            cover {
              id
              absolutePath
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          id
        }
      }
    }
  }
`;
